const trimYearPrefixRegex = /^\d\d\d\d - (.+)$/;

module.exports = {
  /**
   * Capitalizes string
   * @param {string} str
   */
  capitalize: (str) =>
    str &&
    str
      .toString()
      .toLowerCase()
      .split(" ")
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(" "),
  /**
   * Slugify string
   * @param {string} str
   */
  slugify: (str) =>
    str &&
    str
      .toString()
      .toLowerCase()
      .normalize("NFD")
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-"),
  /**
   *Trim year prefix
   *@param {string | null | undefined} title
   */
  trimYearPrefix: (title) => {
    if (title == null) {
      return "";
    }
    const result = trimYearPrefixRegex.exec(title);
    return result != null ? result[1] : title;
  },
  awardOrder: [
    "Årets Berättare",
    "Årets Förnyare",
    "Årets Avslöjande",
    "Årets Röst",
    "Lukas Bonniers Stora Journalistpris",
  ]
};

