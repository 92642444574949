import { css } from "@emotion/core";
import { graphql } from "gatsby";
import React from "react";
import {
  ArticleTemplateQuery,
  ContentfulFluid,
} from "../../graphql-schema-types";
import { Heading1, Intro } from "../components/Atoms/Text";
import ContentPageLayout from "../components/Organisms/ContentPageLayout";
import ContentPageRichText from "../components/Organisms/ContentPageRichText";
import Image, { FluidObject } from "gatsby-image";
import BackLink from "../components/Atoms/BackLink";
import RightColumnShare from "../components/Organisms/RightColumnShare";
import Seo from "../components/Seo";
import { verticalMargin } from "../margins";
import { bpLarge } from "../stylingConstants";
import { trimYearPrefix } from "../polymorphic-utils";

type Props = {
  data: ArticleTemplateQuery;
};

const ImageRender = ({
  image,
  title,
}: {
  image?: FluidObject | null;
  title?: string;
}) =>
  image ? (
    <Image
      fluid={image}
      css={css`
        display: block;
        width: 100%;
        ${verticalMargin(24)}
        @media ${bpLarge}{
          ${verticalMargin(32)}
        }
      `}
      alt={title ?? "Artikelbild"}
    />
  ) : null;

export default function Article({
  data: { contentfulArticlePage: pageQuery },
}: Props) {
  return (
    <ContentPageLayout
      leftColumn={<BackLink />}
      rightColumn={<RightColumnShare />}
    >
      <Seo
        title={pageQuery?.title}
        description={pageQuery?.preamble?.preamble}
        image={pageQuery?.image?.fluid?.src}
      />
      <Heading1>{trimYearPrefix(pageQuery?.title)}</Heading1>
      <Intro>{pageQuery?.preamble?.preamble}</Intro>
      {pageQuery?.image?.fluid?.src && pageQuery.hideArticleImage !== true && (
        <ImageRender
          image={pageQuery.image.fluid}
          title={pageQuery.title ?? undefined}
        />
      )}
      {pageQuery?.images
        ?.filter((image) => image && image.id !== pageQuery?.image?.id)
        .map((image) => (
          <ImageRender
            image={image?.fluid}
            title={image?.description ?? undefined}
          />
        ))}
      {pageQuery?.content?.json && (
        <ContentPageRichText json={pageQuery.content.json} />
      )}
    </ContentPageLayout>
  );
}

export const query = graphql`
  query ArticleTemplate($id: String) {
    contentfulArticlePage(id: { eq: $id }) {
      title
      preamble {
        preamble
      }
      image {
        id
        fluid(maxWidth: 578) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      hideArticleImage,
      images {
        id
        description
        fluid(maxWidth: 578) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      publishDate
      content {
        json
      }
    }
  }
`;
